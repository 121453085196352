import { Auth } from 'aws-amplify';

export const CognitoError = {
  NO_CURRENT_USER: 'No current user',
  REFRESH_TOKEN_EXPIRED: 'Refresh Token has expired',
  INCORRECT_USERNAME_OR_PASSWORD: 'NotAuthorizedException',
};

const getTokens = async () => {
  let tokens;

  try {
    const currentSession = await Auth.currentSession();

    const idToken = currentSession.getIdToken();
    const accessToken = currentSession.getAccessToken();
    const refreshToken = currentSession.getRefreshToken();

    tokens = {
      jwt: {
        idToken: idToken.getJwtToken(),
        accessToken: accessToken.getJwtToken(),
        refreshToken: refreshToken.getToken(),
      },
      decoded: {
        idToken: idToken.decodePayload(),
        accessToken: accessToken.decodePayload(),
      },
    };
  } catch (error) {
    if (error === CognitoError.NO_CURRENT_USER) {
      tokens = null;
    } else {
      console.error(error);
    }
  }

  return tokens;
};

const signIn = async ({ username, password }) => {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    throw new Error(error);
  }
};

const signInViaIdentityProvider = async (provider, storedRoute) => {
  try {
    await Auth.federatedSignIn({
      provider,
      customState: JSON.stringify(storedRoute),
    });
  } catch (error) {
    console.error('error when signing in ', error);
  }
};

const signOut = () => {
  return Auth.signOut();
};

export const AuthenticationService = {
  signIn,
  signInViaIdentityProvider,
  signOut,
  getTokens,
};
