import { VOUCHER_STATUS } from 'libs/constants/statuses';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FormatStatus.module.scss';
import { statusHandler } from './statusHandler';

function FormatStatus({ startDate, endDate, className, style }) {
  const { t } = useTranslation();
  const status = statusHandler(startDate, endDate);
  const statusClass = `${styles.wrapper} ${
    styles[status === VOUCHER_STATUS.inactive ? 'inactive' : 'active']
  } ${className}`;

  return (
    <span className={statusClass} style={style}>
      {t(`voucherManagement.status.${status}`)}
    </span>
  );
}

FormatStatus.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

FormatStatus.defaultProps = {
  startDate: '',
  endDate: '',
  className: '',
  style: {},
};

export default FormatStatus;
