import clsx from 'clsx';
import CustomButton from 'components/CustomButton';
import PermissionWrapper from 'HOCs/permissionWrapper';
import { MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS } from 'libs/constants/modulerights';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Title.module.scss';

function Title(props) {
  const { t } = useTranslation();
  const { handleAddVoucher } = props;

  return (
    <div className="full-width-page-component__wrapper">
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.heading}>{t('voucherManagement.title')}</h1>
          <PermissionWrapper
            permission={
              MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.CREATE_VOUCHER
            }
          >
            <CustomButton
              buttonSize="small"
              className={clsx('back-btn', styles.button)}
              onClick={handleAddVoucher}
            >
              {t('voucherManagement.button.createVoucher')}
            </CustomButton>
          </PermissionWrapper>
        </div>
      </div>
    </div>
  );
}

export default Title;
